<template>
    <div class="stripe-card-list">
        <list ref="stripeCardList" :fs="stripeCardFields" api="stripe/cards" title="Cards" :beforeDelete="handleBeforeDelete"></list>
    </div>
</template>
<script>
import { stripeCardFields } from "@/config";
import List from "@/components/List";
export default {
    components: {
        List
    },
    computed: {
        stripeCardFields() {
            return stripeCardFields;
        }
    },
    methods: {
        handleBeforeDelete(item, list) {
            if (list.length <= 1) {
                const message = "There must be at least (1) valid card on file";
                this.$store.commit("setMessage", message);
                throw message;
            }
        }
    }
};
</script>
