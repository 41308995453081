export default class AddressAutoFill {
    constructor(options) {
        this.element = options.element || document;
        this.cityFiledName = options.cityFiledName || "city";
        this.addressFieldName = options.addressFieldName || "address";
        this.updateCityField = options.updateCityField || function() {};
        this.updateAddressFields = options.updateAddressFields || function() {};
        this.initCallback = options.initCallback || function() {};
    }

    init() {
        this.loadGooleMapScript();
    }

    async loadGooleMapScript() {
        window.initAddressEdit = this.initAddressEdit.bind(this);
        const api_key = (window.INJECT_ENV && window.INJECT_ENV.GOOGLE_MAP_API_KEY) || "AIzaSyCw305DNDiYvcb3Q2SHYagka_RV5fYAxyw";
        const script = `https://maps.googleapis.com/maps/api/js?key=${api_key}&libraries=places&callback=initAddressEdit&language=en`;

        let scripts = document.querySelectorAll(`script`);
        let existScript = [...scripts].find(s => s.src == script);
        if (existScript) {
            this.initAddressEdit();
        } else {
            let scriptEle = document.createElement("script");
            scriptEle.async = true;
            scriptEle.src = script;
            document.head.appendChild(scriptEle);
        }
    }

    initAddressAutoComplete() {
        const input = this.element.querySelector(`[data-vv-name="${this.addressFieldName}"]`);
        const options = {
            componentRestrictions: { country: "us" },
            fields: ["address_components", "geometry"],
            types: ["address"]
        };
        const autocomplete = new google.maps.places.Autocomplete(input, options);
        autocomplete.addListener("place_changed", () => {
            this.fillInAddress(autocomplete);
        });
    }

    initCityAutoComplete() {
        const input = this.element.querySelector(`[data-vv-name="${this.cityFiledName}"]`);
        const options = {
            componentRestrictions: { country: "us" },
            fields: ["address_components", "geometry"],
            types: ["(cities)"]
        };
        const autocomplete = new google.maps.places.Autocomplete(input, options);
        autocomplete.addListener("place_changed", () => {
            this.fillInCity(autocomplete);
        });
    }

    fillInAddress(autocomplete) {
        const place = autocomplete.getPlace();
        let address = "";
        let city = "";
        let state = "";
        let zip = "";
        for (const component of place.address_components) {
            const componentType = component.types[0];

            switch (componentType) {
                case "street_number": {
                    address = `${component.long_name} ${address}`;
                    break;
                }

                case "route": {
                    address += component.short_name;
                    break;
                }

                case "locality":
                    city = component.long_name;
                    break;

                case "sublocality_level_1":
                    city = component.long_name;
                    break;

                case "administrative_area_level_1":
                    state = component.long_name;
                    break;

                case "postal_code":
                    zip = component.long_name;
                    break;
            }
        }
        this.updateAddressFields({ city, address, state, zip });
    }

    fillInCity(autocomplete) {
        let city = "";
        const place = autocomplete.getPlace();
        for (const component of place.address_components) {
            if (component.types[0] === "locality") {
                city = component.long_name;
            }
        }
        this.updateCityField(city);
    }

    initAddressEdit() {
        this.initAddressAutoComplete();
        this.initCityAutoComplete();
        this.initCallback();
    }
}
