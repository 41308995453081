<template>
    <div class="vacation-index">
        <md-tabs ref="tabs" @change="tabChange">
            <md-tab md-label="Cards" :md-active="tabIndex == 0">
                <stripe-card-list ref="list" v-if="tabIndex == 0"></stripe-card-list>
            </md-tab>
            <md-tab md-label="Add Card" :md-active="tabIndex == 1">
                <stripe-card-edit ref="add" :id="id" v-if="tabIndex == 1"></stripe-card-edit>
            </md-tab>
            <md-tab v-if="showTilled" md-label="EzTimePay"> </md-tab>
        </md-tabs>
    </div>
</template>

<script>
import StripeCardList from "@/components/Stripe/StripeCardList";
import StripeCardEdit from "@/components/Stripe/StripeCardEdit";
import { mapGetters } from "vuex";
export default {
    props: ["id"],
    components: {
        StripeCardList,
        StripeCardEdit
    },
    data() {
        return {
            tabIndex: 0
        };
    },
    computed: {
        ...mapGetters({
            paymentSetting: "setting/payment"
        }),
        showTilled() {
            return this.paymentSetting.value && this.paymentSetting.value.use_tilled;
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                if (!this.id) {
                    this.tabIndex = 0;
                } else if (this.id == "new") {
                    this.tabIndex = 1;
                }
            }
        }
    },
    methods: {
        tabChange(index) {
            if (index == 0) {
                this.$router.push("/admin/stripe/cards");
            }
            if (index == 1) {
                this.$router.push("/admin/stripe/cards/new");
            }
            if (index == 2) {
                this.$router.push("/admin/EzTimePay/cards");
            }
        }
    }
};
</script>
