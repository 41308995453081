<template>
    <div class="stripe-card-edit">
        <stripe-new-card @confirm="confirm" @cancel="cancel"></stripe-new-card>
    </div>
</template>
<script>
import StripeNewCard from "@/components/Stripe/StripeNewCard";
export default {
    props: ["id"],
    components: {
        StripeNewCard
    },
    methods: {
        confirm({ token, customer }) {
            this.$router.push("/admin/stripe/cards");
            this.$store.dispatch("user/refreshProfile");
        },
        cancel() {
            this.$router.push("/admin/stripe/cards");
        }
    }
};
</script>
<style lang="less">
.stripe-card-edit {
    .stripe-new-card {
        max-width: 1000px;
        margin: auto;
        .md-card-header {
            display: none;
        }
    }
}
</style>

