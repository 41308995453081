<template>
    <div class="stripe-new-card">
        <md-card>
            <md-card-header>Please Add Your Card</md-card-header>
            <md-card-content>
                <form @submit.prevent="confirm" action id="payment-form" method="post">
                    <md-input-container class="noline">
                        <label>Credit or debit card</label>
                        <div class="stripe-elements">
                            <div id="card-element"></div>
                            <div id="card-errors" role="alert"></div>
                        </div>
                        <input type="hidden" />
                    </md-input-container>
                    <div class="name" v-if="needName">
                        <md-input-container>
                            <label for>Card Holder Name</label>
                            <md-input v-model="name"></md-input>
                        </md-input-container>
                    </div>
                    <div class="address" v-if="needAddress" ref="address">
                        <md-input-container>
                            <label for>Address Line1</label>
                            <md-input data-vv-name="address" v-model="address_line1"></md-input>
                        </md-input-container>
                        <md-input-container>
                            <label for>Address Line2</label>
                            <md-input v-model="address_line2"></md-input>
                        </md-input-container>
                        <md-input-container>
                            <label for>City</label>
                            <md-input data-vv-name="city" v-model="address_city"></md-input>
                        </md-input-container>
                        <md-input-container>
                            <label for>State</label>
                            <md-select v-model="address_state" data-vv-name="state">
                                <md-option :key="ix" :value="state" v-for="(state, ix) in states">{{ state }}</md-option>
                            </md-select>
                        </md-input-container>
                        <md-input-container>
                            <label for>Zip</label>
                            <md-input v-model="address_zip" data-vv-name="zip"></md-input>
                        </md-input-container>
                        <md-input-container>
                            <label for>Country</label>
                            <md-select v-model="address_country" data-vv-name="country">
                                <md-option :key="key" :value="value" v-for="(value, key) in countries">{{ key }}</md-option>
                            </md-select>
                        </md-input-container>
                    </div>
                </form>
            </md-card-content>
            <md-card-actions>
                <md-button @click="cancel">Cancel</md-button>
                <md-button :disabled="errors.any() || loading" @click="confirm" class="md-primary">
                    <md-spinner :md-size="20" class="md-accent spinner" md-indeterminate v-if="!!loading"></md-spinner>Confirm
                </md-button>
            </md-card-actions>
        </md-card>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { states, country } from "@/config";
import AddressAutoFill from "@/components/Address/AddressAutoFillClass";

export default {
    props: {
        id: { type: String },
        signup: { default: false },
        email: { default: "" },
        needName: { default: true },
        needAddress: { default: true }
    },
    data() {
        return {
            isInit: false,
            stripePublicKey: "",
            stripe: null,
            name: "",
            address_line1: "",
            address_line2: "",
            address_city: "",
            address_state: "",
            address_country: "US",
            address_zip: "",
            card: null, //stripe elements
            loading: false
        };
    },
    computed: {
        ...mapGetters({
            user: "user/user"
        }),
        states() {
            return Object.keys(states).sort((a, b) => (a > b ? 1 : -1));
        },
        countries() {
            return { [country.name]: country.code };
        },
        stripeElementsStyle() {
            return {
                base: {
                    color: "#32325d",
                    lineHeight: "18px",
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                    fontSmoothing: "antialiased",
                    fontSize: "16px",
                    "::placeholder": {
                        color: "#aab7c4"
                    }
                },
                invalid: {
                    color: "#fa755a",
                    iconColor: "#fa755a"
                }
            };
        },
        isNew() {
            return !this.id || this.id == "new";
        }
    },
    methods: {
        initAddressAutoFill() {
            const addressAutoFill = new AddressAutoFill({
                element: this.$refs["address"].$el,
                updateCityField: city => {
                    this.address_city = obj.city;
                },
                updateAddressFields: obj => {
                    this.address_line1 = obj.address;
                    this.address_city = obj.city;
                    this.address_state = obj.state;
                    this.address_zip = obj.zip;
                }
            });
            addressAutoFill.init();
        },
        async loadStripeJs() {
            return await require("https://js.stripe.com/v3/");
        },
        async getStripeKey() {
            const data = await this.$store.dispatch("crud/get", {
                api: "settings/EzTimePay-public"
            });
            if (data.stripe_public_key) {
                this.stripePublicKey = data.stripe_public_key;
            } else {
                this.$store.commit("setMessage", "Can't find the stripe key");
                throw "Can't find the stripe key";
            }
        },
        async initStripeElement() {
            if (this.isInit) {
                return;
            }
            await Promise.all([this.loadStripeJs(), this.getStripeKey(), this.setDefault()]);
            // this.$el.querySelector("#card-element").innerHTML = "";
            // this.$el.querySelector("#card-errors").innerHTML = "";
            this.stripe = Stripe(this.stripePublicKey);
            let elements = this.stripe.elements();
            let displayError = this.$el.querySelector("#card-errors");
            let style = this.stripeElementsStyle;
            this.card = elements.create("card", { style, hidePostalCode: true });
            this.card.mount("#card-element");
            this.card.addEventListener("change", function(event) {
                displayError.textContent = event.error ? event.error.message : "";
            });
            this.isInit = true;
        },
        async confirm() {
            if (this.needName && !this.name) {
                return this.$store.commit("setMessage", "Card Holder Name required");
            }
            if (this.needAddress) {
                if (!this.address_line1 || !this.address_city || !this.address_state || !this.address_country || !this.address_zip) {
                    return this.$store.commit("setMessage", "Please Complete Address Fields");
                }
            }
            if (this.loading) {
                return;
            }
            this.loading = true;
            this.$store.commit("setLoading", true);

            let token = await this.getToken();
            if (!token) {
                return this.$store.commit("setMessage", "Get Stripe Card Token Fail");
            }

            if (this.signup) {
                this.loading = false;
                this.$store.commit("setLoading", false);
                return token;
            }

            let customer = await this.createCustomer(token);
            if (!customer) {
                return this.$store.commit("setMessage", "Get Stripe Customer Fail");
            }
            this.$emit("confirm", {
                token,
                customer
            });

            await this.$store.dispatch("user/refreshProfile");
            this.loading = false;
            this.$store.commit("setLoading", false);
        },
        async getToken() {
            let cardData = {};
            if (this.needName) {
                cardData = { ...cardData, ...{ name: this.name } };
            }
            if (this.needAddress) {
                cardData = {
                    ...cardData,
                    ...{
                        address_line1: this.address_line1,
                        address_line2: this.address_line2,
                        address_city: this.address_city,
                        address_state: this.address_state,
                        address_zip: this.address_zip,
                        address_country: this.address_country
                    }
                };
            }
            let result = await this.stripe.createToken(this.card, cardData);
            if (result.error) {
                var errorElement = document.getElementById("card-errors");
                errorElement.textContent = result.error.message;
                this.loading = false;
                throw result.error;
            }
            console.info("Stripe Card Token Created");
            return result.token;
        },
        async createCustomer(token) {
            let result = await this.$store.dispatch("crud/post", {
                api: `stripe/customer`,
                data: {
                    token: token.id,
                    email: this.email
                }
            });
            console.info("Stripe Customer Created");
            return result && result.data;
        },
        async getAddress() {
            if (this.email) {
                const result = await this.$store.dispatch("crud/get", {
                    api: `addresses`,
                    params: {
                        email: this.email
                    }
                });
                const addresses = result.data;
                return addresses && addresses[0];
            } else {
                const result = await this.$store.dispatch("crud/get", {
                    api: `addresses/my`
                });
                const addresses = result.data;
                return addresses && addresses[0];
            }
        },
        async getUser() {
            if (this.email) {
                const result = await this.$store.dispatch("crud/get", {
                    api: `users/email/${this.email}`
                });
                return result;
            } else {
                return this.user;
            }
        },
        async setDefault() {
            if (this.signup) {
                return;
            }
            const user = await this.getUser();
            const address = await this.getAddress();
            if (user) {
                this.name = user.name;
            }
            if (address) {
                this.address_line1 = address.address;
                this.address_line2 = "";
                this.address_city = address.city;
                this.address_state = address.state;
                this.address_country = this.countries[address.country];
                this.address_zip = address.zip + "";
            }
        },
        cancel() {
            this.$emit("cancel");
        }
    },
    async mounted() {
        this.initStripeElement();
        this.initAddressAutoFill();
    }
};
</script>

<style lang="less">
@import "../../mixins.less";
.stripe-new-card {
    .form-default();
    .md-card {
        margin: 0;
    }
    .md-card-header {
        padding: 0 16px;
    }
    .stripe-elements {
        width: 100%;
        .StripeElement {
            background-color: white;
            height: 40px;
            width: 100%;
            padding: 10px 12px;
            border-radius: 4px;
            border: 1px solid transparent;
            box-shadow: 0 1px 3px 0 #e6ebf1;
            -webkit-transition: box-shadow 150ms ease;
            transition: box-shadow 150ms ease;
        }

        .StripeElement--focus {
            box-shadow: 0 1px 3px 0 #cfd7df;
        }

        .StripeElement--invalid {
            border-color: #fa755a;
        }

        .StripeElement--webkit-autofill {
            background-color: #fefde5 !important;
        }

        #card-errors {
            color: #fa755a;
            margin-top: 5px;
            padding-left: 24px;
        }
    }
}
</style>
